import axios from "axios";
import React from "react";
import Countdown from "react-countdown";
import Modal from "react-responsive-modal";
import apis from "../Apis";
import arrow from "../assets/ARROW.png";
import Round1 from "../assets/Round 1.png";
import Round2 from "../assets/Round 2.png";
import Round3 from "../assets/Round 3.png";
import Round4 from "../assets/Round 4.png";
import Round5 from "../assets/Round 5.png";
import Round6 from "../assets/Round 6.png";
import Round7 from "../assets/Round 7.png";
import star from "../assets/star.png";

import ok from "../assets/win.jpg";

class FortuneWheel extends React.Component {
  state = {
    list: [
      { label: "Muffin", image: Round1, bgColor: "#ef4224" },
      { label: "Lolipop", image: Round2, bgColor: "#f78f21" },
      { label: "Fish", image: Round3, bgColor: "#3cc6f5" },
      { label: "Donut", image: Round4, bgColor: "#ed186a" },
      { label: "Chicken", image: Round5, bgColor: "#5952a3" },
      { label: "IceCream", image: Round6, bgColor: "#009b83" },
      { label: "Toffee", image: Round7, bgColor: "#ffc025" },
      { label: "Star", image: star, bgColor: "#919191" },
    ],
    radius: 75, // PIXELS
    rotate: 0, // DEGREES
    easeOut: 0, // SECONDS
    angle: 0, // RADIANS
    top: null, // INDEX
    offset: null, // RADIANS
    net: null, // RADIANS
    result: null, // INDEX
    spinning: false,
    spinComplete: false,
    isSpin: false,
    countdownTime: null,
    isModal: false,
    isModalClaim: false,
    loading: false,
    submitLoading: false,
  };
  componentDidMount() {
    // generate canvas wheel on load
    this.renderWheel();
    this.getSpinTime();
  }

  renderWheel() {
    // determine number/size of sectors that need to created
    let numOptions = this.state.list.length;
    let arcSize = (2 * Math.PI) / numOptions;
    this.setState({
      angle: arcSize,
    });

    // get index of starting position of selector
    this.topPosition(numOptions, arcSize);

    // dynamically generate sectors from state list
    let angle = 0;
    for (let i = 0; i < numOptions; i++) {
      let text = this.state.list[i]?.label;
      let image = this.state.list[i]?.image;
      let bgColor = this.state.list[i]?.bgColor;
      this.renderSector(i + 1, text, image, angle, arcSize, bgColor);
      angle += arcSize;
    }
  }

  topPosition = (num, angle) => {
    // set starting index and angle offset based on list length
    // works upto 9 options
    let topSpot = null;
    let degreesOff = null;
    if (num === 9) {
      topSpot = 7;
      degreesOff = Math.PI / 2 - angle * 2;
    } else if (num === 8) {
      topSpot = 6;
      degreesOff = 0;
    } else if (num <= 7 && num > 4) {
      topSpot = num - 1;
      degreesOff = Math.PI / 2 - angle;
    } else if (num === 4) {
      topSpot = num - 1;
      degreesOff = 0;
    } else if (num <= 3) {
      topSpot = num;
      degreesOff = Math.PI / 2;
    }

    this.setState({
      top: topSpot - 1,
      offset: degreesOff,
    });
  };

  renderSector(index, text, image, start, arc, color) {
    // create canvas arc for each list element
    let canvas = document.getElementById("wheel");
    let ctx = canvas.getContext("2d");
    let x = canvas.width / 2;
    let y = canvas.height / 2;
    let radius = this.state.radius;
    let startAngle = start;
    let endAngle = start + arc;
    let angle = index * arc;
    let baseSize = radius * 3.33;
    let textRadius = baseSize - 150;
    let img = document.createElement("img");
    img.src = image;
    img.alt = "text";
    // -------------------------------Wheel Border-------------------------------

    ctx.beginPath();
    ctx.arc(x, y, 4, 0, 2 * Math.PI);
    ctx.lineWidth = radius * 4.1;
    ctx.strokeStyle = "#f7e230";
    ctx.stroke();

    ctx.beginPath();
    ctx.arc(x, y, 11, 0, 2 * Math.PI);
    ctx.lineWidth = radius * 4.4;
    ctx.strokeStyle = "black";
    ctx.stroke();

    // ctx.beginPath();
    // ctx.arc(x, y, 2, 0, 2 * Math.PI);
    // ctx.lineWidth = radius * 4.3;
    // ctx.strokeStyle = "lightBlue";
    // ctx.stroke();

    ctx.beginPath();
    ctx.arc(x, y, 2, 0, 2 * Math.PI);
    ctx.lineWidth = radius * 4.7;
    ctx.strokeStyle = "#c6168d";
    ctx.stroke();
    // ---------------------------------------------------------------------------
    ctx.beginPath();
    ctx.arc(x, y, radius, startAngle, endAngle, false);
    ctx.lineWidth = radius * 2;
    ctx.strokeStyle = color;
    ctx.font = "17px Arial";
    ctx.fillStyle = "black";
    ctx.stroke();

    img.addEventListener("load", (e) => {
      ctx.save();
      ctx.translate(
        baseSize + Math.cos(angle - arc / 2) * textRadius,
        baseSize + Math.sin(angle - arc / 2) * textRadius
      );
      ctx.rotate(angle - arc / 2 + Math.PI / 2);
      // ctx.fillText(text, -ctx.measureText(text).width / 2, 0);
      ctx.drawImage(img, -34, -34, 70, 70);
      ctx.restore();
    });
  }

  spin = () => {
    // set random spin degree and ease out time
    // set state variables to initiate animation
    let randomSpin = Math.floor(Math.random() * 900) + 500;
    this.setState({
      rotate: randomSpin,
      easeOut: 2,
      spinning: true,
    });

    // calcalute result after wheel stops spinning
    setTimeout(() => {
      this.getResult(randomSpin);
    }, 2000);
  };

  getResult = (spin) => {
    const { angle, top, offset, list } = this.state;
    let netRotation = ((spin % 360) * Math.PI) / 180; // RADIANS
    let travel = netRotation + offset;
    let count = top + 1;
    while (travel > 0) {
      travel = travel - angle;
      count--;
    }
    let result;
    if (count >= 0) {
      result = count;
    } else {
      result = list.length + count;
    }

    // set state variable to display result
    this.setState({
      net: netRotation,
      result: result,
      spinComplete: true,
    });
  };

  reset = () => {
    // reset wheel and result
    this.setState({
      rotate: 0,
      easeOut: 0,
      result: null,
      spinning: false,
      spinComplete: false,
    });
  };

  // ---------------------------Get Spin Time-----------------------------
  getSpinTime = () => {
    this.setState({ loading: true });
    const memberId = window.location.href.split("/").pop();
    axios.get(apis.fortuneWheel.wheelTiming(memberId)).then((resp) => {
      if (resp?.data?.length !== 0) {
        this.setState({
          isSpin: resp.data[0]?.Type !== "N",
          isModal: resp.data[0]?.Type === "N",
          loading: false,
          countdownTime:
            (parseInt(resp.data[0]?.Hour) * 3600 +
              parseInt(resp.data[0]?.Minute) * 60 +
              parseInt(resp.data[0]?.Second)) *
            1000,
        });
      }
    });
  };
  // Renderer callback with condition
  renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      // return <Completionist />;
      // alert("go");
    } else {
      // Render a countdown
      return (
        <span>
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  // -------------------------Submit Win Value----------------------------
  HandleSubmitWinValue = () => {
    this.setState({ submitLoading: true });
    const winValue = this.state.list[this.state.result]?.label;
    const memberId = window.location.href.split("/").pop();

    axios.get(apis.fortuneWheel.winValue(memberId, winValue)).then((resp) => {
      if (resp?.data?.length !== 0 && resp?.data[0]?.Status === "Success") {
        this.getSpinTime();
        this.setState({ isModalClaim: true });
      }
    });
    setTimeout(() => {
      this.setState({ isModalClaim: false, submitLoading: false });
      this.reset();
    }, 50000);
  };

  render() {
    return (
      <div className="App">
        <div className="wheel-wrapper">
          <img id="selector" src={arrow} alt="arrow" />
          <canvas
            id="wheel"
            width="500"
            height="500"
            style={{
              WebkitTransform: `rotate(${this.state.rotate}deg)`,
              WebkitTransition: `-webkit-transform ${this.state.easeOut}s ease-out`,
              width: "100%",
            }}
          />
        </div>
        <div className="buttonAction">
          {this.state.spinComplete ? (
            <>
              <button
                type="button"
                id="reset"
                disabled={this.state.submitLoading}
                onClick={this.HandleSubmitWinValue}
              >
                Claim Reward
              </button>
              <div className="display">
                <h2 className="readout">Hurrah!! You have won </h2>
                <h1 className="readout">
                  <span
                    id="result"
                    style={{
                      color: this.state.list[this.state.result]?.bgColor,
                    }}
                  >
                    {this.state.list[this.state.result]?.label}
                  </span>
                </h1>
              </div>
            </>
          ) : (
            <button
              type="button"
              id="spin"
              disabled={
                !this.state.isSpin || this.state.spinning || this.state.loading
              }
              onClick={this.spin}
            >
              Spin & Win
            </button>
          )}
        </div>
        <Modal
          open={this.state.isModalClaim}
          onClose={() => this.setState({ isModalClaim: false })}
        >
          <div
            style={{
              width: "220px",
              height: "200px",
              background: "#fff",
              textAlign: "center",
              borderRadius: "10px",
              boxShadow: " 0 8px 12px 0 rgba(244, 242, 242, 0.226)",
            }}
          >
            <img
              src={ok}
              style={{
                width: "120px",
                height: "auto",
                marginTop: "5%",
              }}
            />
            <h1 style={{ fontSize: "16px" }}>
              Hurrah!! You have won
              <br />
              <span
                id="result"
                style={{
                  color: this.state.list[this.state.result]?.bgColor,
                }}
              >
                {this.state.list[this.state.result]?.label}
              </span>
            </h1>
          </div>
        </Modal>
        <Modal
          open={this.state.isModal && !this.state.isModalClaim}
          onClose={() => this.setState({ isModal: false })}
    
          closeOnOverlayClick={false}
        >
          <h2>
            {this.state.countdownTime && (
              <>
                {" "}
                Time Left:{" "}
                <Countdown
                  date={Date.now() + this.state.countdownTime}
                  renderer={this.renderer}
                />
              </>
            )}
          </h2>
        </Modal>

        {/* <div className="countdownDisplay">
          {this.state.countdownTime && (
            <Countdown date={Date.now() + this.state.countdownTime} renderer={this.renderer} />
          )}
        </div> */}
      </div>
    );
  }
}

export default FortuneWheel;
