import "./App.css";
import "react-responsive-modal/styles.css";
import FortuneWheel from "./FortuneWheel";

const App = () => {
  return (
    <div className="App">
      <FortuneWheel />
    </div>
  );
};

export default App;
